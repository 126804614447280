import { getImage as _getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks';

interface SimpleDataParent {
  childImageSharp?: { gatsbyImageData?: IGatsbyImageData };
}

const getImage = (
  imageParent: SimpleDataParent | undefined
): IGatsbyImageData | undefined =>
  !imageParent ? imageParent : _getImage(imageParent as FileNode);

export default getImage;
