import type {
  StoryData,
  StoryblokComponentType,
} from 'gatsby-source-storyblok';

export type GQLStory = GatsbyTypes.StoryblokStoryFragment;

export default function getStory<
  T = StoryblokComponentType<string> & { [index: string]: unknown }
>(story: GQLStory): StoryData<T> {
  const contentBlok = JSON.parse(story.content || '');
  return { ...story, content: contentBlok } as unknown as StoryData<T>;
}
