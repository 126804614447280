import {
  Text,
  InlineType,
  TextLinkMark,
} from '../new/components/RichText/InlineRenderer';
import { ContentBit } from '../new/components/RichText/BlockRenderer';
import { PageContextProps } from '../storyblok/entry';
import { ensureTrailingSlash } from './ensureTranilingSlash';

type ProcessMode = 'getLinks' | 'addLinks';

type ContentNode = {
  type: string;
  content?: ContentNode[];
  [key: string]: unknown;
};

const DEFAULT_PHRASES = new Set(['Generative AI', 'Generative UI']);

/**
 * Escapes special characters in a string for use in a RegExp.
 * @param string - The input string to escape.
 * @returns The escaped string.
 */
const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

/**
 * Creates compound words by replacing spaces with non-breaking spaces
 * in specified phrases within a content string, ignoring case.
 * @param content - The input content string.
 * @param phrases - A Set of phrases to process.
 * @returns The processed content with non-breaking spaces in specified phrases.
 */
const createCompoundWords = (content: string, phrases = DEFAULT_PHRASES) => {
  const nonBreakingSpace = '\u00A0';
  let result = content;

  for (const phrase of phrases) {
    const regex = new RegExp(`\\b(${escapeRegExp(phrase)})\\b`, 'gi');
    result = result?.replace(regex, (match) =>
      match?.replace(/ /g, nonBreakingSpace)
    );
  }

  return result;
};

/**
 * Adds auto-links to text nodes within paragraphs in Storyblok rich text content.
 * Only updates text nodes within 'paragraph' nodes, and skips headings and other node types.
 * Skips self-linking and nodes that already contain links.
 *
 * @param content - The Storyblok rich text content as a JSON string
 * @param linksList - List of auto-link phrases and slugs to be applied
 * @param contentPath - slug for page being processed
 * @returns Transformed Storyblok content string with links applied
 */
const createAutoLinks = (
  content: string,
  contentPath: string,
  linksList: PageContextProps['autoLinks'] = { nodes: [] }
): string => {
  const addedLinks = new Set<string>();

  const cleanPath = contentPath.includes('categories')
    ? ensureTrailingSlash(contentPath.replace('categories', ''))
    : contentPath;

  // Prevent self-linking
  addedLinks.add(cleanPath);

  /**
   * Parses link list values into JSON objects and setting
   * custom url if contentPath matches one of the object keys
   * @param nodes - The linkslist nodes to process
   * @param path - the contentPath to check for custom url
   * @returns The updated nodes with custom urls applied for specified contentPaths
   */
  const parseLinksList = (nodes: typeof linksList['nodes'], path: string) => {
    return nodes.map((link) => {
      try {
        const values = JSON.parse(link.value) as Record<string, string>;
        return {
          name: link.name,
          value: values[path] ?? values.default,
        };
      } catch (e) {
        // console.log('autolink parsing error:', e, link);
        // catch any plain strings in the datasource
        return {
          name: link.name,
          value: link.value,
        };
      }
    });
  };

  /**
   * Adds links to a text node if it matches phrases in the linksList
   * @param textNode - The text node to process
   * @param phrase - Phrase to match within the text
   * @param slug - The URL to link the matched phrase to
   * @returns The updated text node with links applied
   */
  const addLinksToText = (
    textNode: Text,
    phrase: string,
    slug: string
  ): Text | Text[] => {
    const regex = new RegExp(`\\b(${escapeRegExp(phrase)})\\b`, 'gi');
    if (!regex.test(textNode.text)) return textNode;
    if (addedLinks.has(slug)) return textNode;

    const parts = textNode.text.split(regex);
    return parts.map((part) => {
      const foundPhrase = part.toLowerCase() === phrase.toLowerCase();

      if (foundPhrase && !addedLinks.has(slug)) {
        addedLinks.add(slug);

        return {
          type: 'text',
          text: part,
          marks: [
            ...(textNode.marks || []),
            {
              type: 'link',
              attrs: {
                href: slug,
                linktype: 'url',
              },
            },
          ],
        };
      }

      return {
        ...textNode,
        text: part,
      };
    });
  };

  /**
   * Processes a single node, applying auto-links to text nodes within paragraph nodes.
   * @param node - The node to process
   * @param mode - The type of process to run against the content.
   * @param parentType - The type of the parent node (e.g., 'paragraph', 'heading')
   * @returns The transformed node
   */
  const processNode = (
    node: InlineType | ContentBit,
    mode: ProcessMode,
    parentType?: string
  ): InlineType | ContentBit => {
    if (node.type !== 'text') {
      return node;
    }

    // Get current link nodes
    const isLinkAlready = node.marks?.some((mark) => mark.type === 'link');
    if (mode === 'getLinks' && isLinkAlready) {
      const url = node.marks?.filter(
        (mark) => mark.type === 'link'
      )?.[0] as TextLinkMark;

      if (url.attrs.href.includes('categories/')) {
        const updatedUrl = ensureTrailingSlash(
          url.attrs.href.replace('categories/', '')
        );
        addedLinks.add(updatedUrl);
      } else {
        addedLinks.add(url.attrs.href);
      }

      return node;
    }

    // Always skip current link nodes
    if (isLinkAlready) return node;

    // Add new links
    const isParagraph = node.text && parentType === 'paragraph';
    if (mode === 'addLinks' && isParagraph) {
      let processedNode: Text | Text[] = node;
      const updatedlinks = parseLinksList(linksList.nodes, cleanPath);

      updatedlinks.forEach((link) => {
        if (Array.isArray(processedNode)) {
          processedNode = processedNode.flatMap((subNode) =>
            addLinksToText(subNode, link.name, link.value)
          );
        } else {
          processedNode = addLinksToText(processedNode, link.name, link.value);
        }
      });

      return processedNode;
    }

    return node;
  };

  /**
   * Recursively processes content nodes and their children, maintaining parent node types.
   * @param item - The content item to process (can be an object or array)
   * @param mode - The type of process to run against the content.
   * @param parentType - The type of the parent node
   * @returns The processed content item
   */
  const processContent = (
    item: ContentNode | ContentNode[],
    mode: ProcessMode,
    parentType?: string
  ): ContentNode | ContentNode[] => {
    if (Array.isArray(item)) {
      return item.flatMap((child) => processContent(child, mode, parentType));
    }

    if (typeof item === 'object' && item !== null) {
      const newItem: ContentNode = { ...item };

      for (const [key, value] of Object.entries(item)) {
        newItem[key] =
          typeof value === 'object' && value !== null
            ? processContent(value as ContentNode, mode, item.type)
            : value;
      }

      return processNode(newItem as ContentBit | InlineType, mode, parentType);
    }
    return item;
  };

  const parsedContent = JSON.parse(content);
  const processedContent = processContent(parsedContent, 'getLinks');
  const autoLinkedContent = processContent(processedContent, 'addLinks');
  return JSON.stringify(autoLinkedContent);
};

export { createAutoLinks, createCompoundWords, DEFAULT_PHRASES };
